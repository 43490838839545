import firebase from './firebase';
import 'firebase/database';
import 'firebase/auth';
import { DateTime } from 'luxon';

import CommonDiceRoll from '../common/CommonDiceRoll';

import store from '../reducers';
const database = firebase.database();


export function getRollHistoryRef(rolls) {

  const userId = firebase.auth().currentUser?.uid;
  return userId ? database.ref(`user-rolls/${userId}`) : null;
}

export function getRollHistory(userId?) {
  if (!userId) {
    userId = firebase.auth().currentUser?.uid;
  }
  let user = null;
  return database.ref(`/users/${userId}`).once('value').then((snapshot) => {
    user = snapshot.val();
    if (user && user.uid !== userId) {  userId = user.uid };

    return database.ref(`user-rolls/${userId}`).once('value');
  });
  
}

export function fetchUserDetail(usernameOrId) {
  database.ref(`/users/${usernameOrId}`).once('value').then((snapshot) => {
    const user = snapshot.val();
    store.dispatch({
      type: 'UPDATE_USER_CUSTOM_DATA',
      customData: {username: user?.username}
    })
  })
}

export function addRollsToHistory(rolls) {
  const userId = firebase.auth().currentUser?.uid;

  rolls.forEach((roll) => {
    if (database && userId) {
      const rollId = database.ref().child('rolls').push().key;
      let updates = {};
      updates[`rolls/${rollId}`] = {
        userId: userId,
        createdAt: roll.createdAt,
        asString: roll.asString,
        name: roll.name,
        resultTokens: roll.resultTokens,
        sum: roll.sum,
      }
      updates[`user-rolls/${userId}/${rollId}`] = {
        createdAt: roll.createdAt,
        asString: roll.asString,
        name: roll.name,
        resultTokens: roll.resultTokens,
        sum: roll.sum,
      };
      try {
        database.ref().update(updates);
      } catch (error) {
        console.log(error);
      }
      getRollHistory().then((snapshot) => {
        const rolls = snapshot.val() 
          ? Object.entries(snapshot.val()).map(([key, roll]) => {
              return new CommonDiceRoll({
                ...roll,
                id: key,
              })
            })
          : [];
        store.dispatch({
          type: 'UPDATE_ROLL_HISTORY',
          history: rolls
        })
      })
    }
  })
}

export function login(email, password, onError) {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        fetchUserDetail(userCredential.user.uid);
      })
      .catch((error) => {
        onError(error.message);
      })
    })
    .catch((error) => {
      onError(error.message);
    })
}

export function register(username, email, password, onError) {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = {
          uid: userCredential.user.uid,
          createdAt: DateTime.utc(),
          username, 
        }
        let updates = {};
        updates[`users/${username}`] = user;
        updates[`users/${userCredential.user.uid}`] = user;

        try {
          database.ref().update(updates);
        } catch (error) {
          onError(error.message);
        }

        const currentUser = firebase.auth().currentUser;
        currentUser.updateProfile({
          displayName: username
        })

      })
      .catch((error) => {
        onError(error.message);
      })
    })
    .catch((error) => {
      onError(error.message);
    })
}

export function logout() {
    firebase.auth().signOut().then(() => {
      window.location.reload(false);
      }).catch((error) => {
        console.log(error)
      });
}