import './App.css';
import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';

import SiteHeader from './components/SiteHeader';
import Dashboard from './components/Dashboard';
import MenuBar from './components/MenuBar';
import Sidebar from './components/Sidebar';
import AuthPage from './auth/AuthPage';
import Profile from './components/Profile';

import CommonInterstitialPage from './common/CommonInterstitialPage';

const path = require('path')
require('dotenv').config({ path: path.resolve(__dirname, '../.env') });

class App extends React.Component {
  render() {
    const authed = this.props.state.user?.data;
console.log(process.env);

    return (
        <div className={styles.app}>
        <Router>
        <div>
            <div className={classnames(styles.mainPageWrapper, {
              [styles.mainPageFull]: this.props.state.layout.hideSidebar
            })}>
            <section className={styles.mainPage}>
              <MenuBar />
              <SiteHeader />
              <Switch>
                <Route exact path="/login" render={(props) => {
                  return (authed ? <Redirect to="/" /> : <AuthPage />)
                }}/>
                <Route exact path="/register" render={(props) => {
                  return (authed ? <Redirect to="/" /> : <AuthPage register={true} />)
                }}/>
                <Route exact path="/user/:username" render={(props) => <Profile {...props}/>} />
                <Route exact path="/interstitial" render={(props) => <CommonInterstitialPage {...props} />} />
                <Route exact path="/"><Dashboard /></Route>
              </Switch>
            </section>
            </div>
            <Sidebar />
        </div>
        </Router>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  }
}

export default connect(mapStateToProps)(App);
