import React, { createRef } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import CommonCard from '../../common/CommonCard';
import CommonInput from '../../common/CommonInput';
import CommonButton from '../../common/CommonButton';

import { login, register } from '../../helpers/api';

class AuthPage extends React.Component {
	constructor(props) {
		super(props);
		this.usernameRef = createRef();
		this.emailRef = createRef();
		this.passwordRef = createRef();
		this.passwordRepeatRef = createRef();

		this.login = this.login.bind(this);
		this.register = this.register.bind(this);

		this.onError = this.onError.bind(this);

		this.state = {
			errorMessage: '',
			showErrorMessage: false,
		}
	}
	onError(errorMessage) {
		this.setState({
			errorMessage,
			showErrorMessage: true,
		})
	}
	login(e) {
		e.preventDefault();
		const email = this.emailRef.current?.value;
		const password = this.passwordRef.current?.value;
		login(email, password, this.onError)
	}
	register(e) {
		e.preventDefault();
		const username = this.usernameRef.current?.value;
		const email = this.emailRef.current?.value;
		const password = this.passwordRef.current?.value;
		if (password !== this.passwordRepeatRef.current?.value) {
			return false;
		}
		register(username, email, password, this.onError);
	}
	render() {
		return (
			<CommonCard className={styles.authCard}>
			<form onSubmit={this.props.register ? this.register : this.login}>
				{!this.props.register &&
				<div>
					{/*<h1>Choose a method to log in</h1>*/}
					<h1>Log in with your email</h1>
					<p>New to dicey.app? <Link to='/register'>Register</Link> instead<br />
					I forgot my password</p>
				</div>
				}
				{this.props.register &&
				<div>
					{/*<h1>Choose a method to register</h1>*/}
					<h1>Register a new account</h1>
					<p>Have an account? <Link to='login'>Log in</Link> instead</p>
				</div>
				}
				{/*<section className={styles.authOptions}>
					<div className={styles.authOption}>
						Google
					</div>
					<div className={styles.authOption}>
						Facebook
					</div>
					<div className={styles.authOption}>
						Twitter
					</div>
					<div className={styles.authOption}>
						Email
					</div>
				</section>
				*/}
				<section className={styles.authEmailForm}>
				{this.props.register &&
					<div className={styles.authEmailFormInput}>	
						<CommonInput 
							type="text"
							placeholder="Username"
							ref={this.usernameRef}
						/>
					</div>
				}
				<div className={styles.authEmailFormInput}>	
					<CommonInput 
						type="text"
						placeholder="Email"
						ref={this.emailRef}
					/>
				</div>
				<div className={styles.authEmailFormInput}>	
					<CommonInput 
						type="password"
						placeholder="Password"
						ref={this.passwordRef}
					/>
				</div>
				{this.props.register &&
				<div className={styles.authEmailFormInput}>	
					<CommonInput 
						type="password"
						placeholder="Confirm Password"
						ref={this.passwordRepeatRef}
					/>
				</div>
				}
				<CommonButton
					type='submit'
				>
					{this.props.register ? 'Register' : 'Login'}
				</CommonButton>
				<div className={classnames(styles.errorMessage, {
					[styles.visible]: this.state.showErrorMessage,
				})}>
					{this.state.errorMessage}
				</div>
				</section>
			</form>
			</CommonCard>
		)
	}
}

const mapStateToProps = state => {
    return {
        state
    }
}


export default connect(mapStateToProps)(AuthPage);