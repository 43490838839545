import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from '../../helpers/api';

export class MenuBar extends React.Component {
	render() {		
		return (
			<section className={styles.menuBar}>
				<ul>
					{!this.props.state.user.data && 
					<Fragment>
						<Link to="/login"><li>Login</li></Link>
						<Link to="/register"><li>Register</li></Link>
					</Fragment>
					}
{/*					{this.props.state.user.data &&
						<Link><li 
						onClick={() => store.dispatch({
							type: 'LAYOUT__TOGGLE_SIDEBAR'
						})}>Profile</li></Link>
					}*/}
					{this.props.state.user.data &&
					<Fragment>
						<Link
						to={this.props.state.user.data
							? `/user/${this.props.state.user.data.displayName}`
							: '/'}
						>
						<li>Profile</li></Link>
						<Link><li 
						onClick={() => logout()
						}>Logout</li></Link>
					</Fragment>
					}
				</ul>
			</section>
		
		)
	}
}

const mapStateToProps = state => {
  return {
    state
  }
}

export default connect(mapStateToProps)(MenuBar);