import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from './helpers/firebase'
import store from './reducers';
import { Provider } from 'react-redux';

import { getRollHistory } from './helpers/api';
import CommonDiceRoll from './common/CommonDiceRoll';

firebase.auth().onAuthStateChanged(function(user) {
    store.dispatch({
      type: 'UPDATE_USER',
      user: user,
    });    
    getRollHistory().then((snapshot) => {
      const rolls = snapshot.val() 
        ? Object.entries(snapshot.val()).map(([key, roll]) => {
            return new CommonDiceRoll({
              ...roll,
              id: key,
            })
          })
        : [];
      store.dispatch({
        type: 'UPDATE_ROLL_HISTORY',
        history: rolls
      })
    })

});

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
