import React from 'react';
import styles from './styles.module.scss';

class CommonInputInner extends React.Component {
	render() {
		return (
		<div className={styles.commonInputWrapper}>
			<input 
				{...this.props}
				className={styles.commonInput}
				ref={this.props.innerref}
			/>
			<div className={styles.commonInputBottomText}>
				{this.props.bottomText}
			</div>
		</div>
		)
	}
}

const CommonInput = React.forwardRef((props, ref) =>
	<CommonInputInner {...props} innerref={ref}/>
);

export default CommonInput;