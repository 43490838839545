import { DiceRollReducer } from './DiceRollReducer';
import { UserReducer } from './UserReducer';
import { LayoutReducer } from './LayoutReducer';
import { combineReducers, createStore } from 'redux';

export const rootReducer = combineReducers({
	rolls: DiceRollReducer,
	user: UserReducer,
	layout: LayoutReducer,
})

const store = createStore(rootReducer);

export default store;