import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import classnames from 'classnames';

import DiceTextInput from '../DiceTextInput';

class DiceResults extends React.Component {
    /*
        this.props.card - whether the component is wrapped in CommonCard
    */
    constructor(props) {
        super(props);
        this.renderRoll = this.renderRoll.bind(this);
    }
    renderRoll(roll) {
        return (
            <Fragment>
            {roll.asTokens.map((dice, index) => {
                if (!dice || !dice.includes('d')) {
                    return '';
                }
                let results;
                try {
                    results = roll.resultTokens[index];
                } catch(e) {
                    return 'Invalid dice syntax.';
                };
                return results.map((result, rIndex) =>
                    <GraphicDie
                        type={dice.slice(dice.indexOf('d'))}
                        value={result}
                        key={dice + rIndex}
                    />
                )
            })}
            </Fragment>
        )
    }
    render() {
        const rolls = this.props.state.rolls.current;
        // TODO implement this with multiple rolls at a time later
        const main = (
            <Fragment>
                <section className={classnames(styles.diceTotal, {
                    [styles.visible]: rolls
                })}>
                    {
                        rolls 
                        ? rolls[0].sum
                        : 0
                    }
                </section>
                {rolls ?
                <Fragment>
                <section className={styles.diceResults}>
                    {rolls && rolls.map((roll) => this.renderRoll(roll))}
                </section>
                </Fragment>
                : <div className={styles.defaultText}>Roll the dice!</div>
                }
            </Fragment>
        )
        return (
        <Fragment>
            {this.props.card ?
            <section className={styles.diceDisplay}>
                {main}
                <DiceTextInput />
            </section>
            : <section>{main}</section>
            }
        </Fragment>
        )
    }
}

class GraphicDie extends React.Component {
    constructor(props) {
        super(props);
        // Dice that have an image
        this.graphicDice = new Set(
            'd4',
            'd6',
            'd8',
            'd10',
            'd12',
            'd20'
        )
    }
    render() {
        return (
            <Fragment>

            <div className={styles.graphicDie} >
                <span className={classnames({
                        [styles.activeDie]: this.props.type === 'd4',
                    })}
                > 
                <svg
                    className={classnames({
                        [styles.activeDie]: this.props.type === 'd4',
                    })}  
                    viewBox="0 0 20 20" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="10,0 0,20 20,20"/>
                </svg>
                </span>
 
                <span className={classnames({
                        [styles.activeDie]: this.props.type === 'd6',
                    })}
                >
                <svg viewBox="0 0 20 20" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0" y="0" width="100%" height="100%"/>
                </svg>
                </span>

                <span className={classnames({
                        [styles.activeDie]: this.props.type === 'd8',
                    })}
                >
                <svg viewBox="0 0 20 20" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="10,0 0,5 0,15 10,20, 20,15, 20,5"/>
                    <polygon className={styles.dieShadow} points="10,0 0,15, 20,15"/>
                    <polygon className={styles.dieShadowDark} points="10,20 0,15, 20,15"/>
                </svg>
                </span>

                <span className={classnames({
                        [styles.activeDie]: this.props.type === 'd10',
                    })}
                >
                <svg viewBox="0 0 20 20" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="10,0 0,7 0,13, 10,20, 20,13 20,7"/>
                    <polygon className={styles.dieShadow} points="10,0 0,7 0,13 4,13"/>
                    <polygon className={styles.dieShadow} points="10,0 20,7 20,13 16,13"/>
                    <polygon className={styles.dieShadowDark} points="10,20 0,13 4,13 10,16 16,13 20,13"/>
                </svg>
                </span>
 
                <span className={classnames({
                        [styles.activeDie]: this.props.type === 'd12',
                    })}
                >
                <svg viewBox="0 0 24 24" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="12,0 5,2 0,8 0,16 5,22 12,24 19,22 24,16 24,8, 19,2"/>
                    <polygon className={styles.dieLightShadow} points="0,8 0,16 5,22 7,17 5,9 "/>
                    <polygon className={styles.dieLightShadow} points="24,8 24,16 19,22 17,17 19,9 "/>
                    <polygon className={styles.dieShadow} points="12,0 5,2 0,8 5,9 12,5"/>
                    <polygon className={styles.dieShadow} points="12,0 19,2 24,8 19,9 12,5"/>
                    <polygon className={styles.dieShadowDark} points="5,22 12,24 19,22 17,17 7,17"/>
                  </svg>
                </span>

                <span className={classnames({
                        [styles.activeDie]: this.props.type === 'd20',
                    })}
                >
                <svg viewBox="0 0 20 20" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="10,0 1,5 1,15 10,20, 19,15, 19,5"/>
                    <polygon className={styles.dieLightShadow} points="10,5 4,15 1,5"/>
                    <polygon className={styles.dieLightShadow} points="10,5 16,15 19,5"/>
                    <polygon className={styles.dieShadow} points="10,0 1,5 19,5"/>
                    <polygon className={styles.dieShadow} points="1,5 1,15 4,15"/>
                    <polygon className={styles.dieShadow} points="19,5 19,15 16,15"/>
                    <polygon className={styles.dieShadow} points="10,20 4,15 16,15"/>
                    <polygon className={styles.dieShadowDark} points="10,20 4,15 1,15"/>
                    <polygon className={styles.dieShadowDark} points="10,20 16,15 19,15"/>
                </svg>
                </span>

                <p>{this.props.value}</p>
            </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

export default connect(mapStateToProps)(DiceResults);