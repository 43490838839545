import React from 'react';
import styles from './styles.module.scss';

class CommonCard extends React.Component {
	render() {
		return (
			<section className={`${styles.commonCard} ${this.props.className}`}>
				{this.props.headerText &&
				<header className={styles.commonCardHeader}>
					<span>{ this.props.headerText }</span>
				</header>
				}
				<div className={styles.commonCardContent}>
					{ this.props.children }
				</div>
			</section>
		)
	}
}

export default CommonCard;