import React from 'react';
import styles from './styles.module.scss';

class CommonButton extends React.Component {
	render() {
		return (
			<input
				type={this.props.type || 'button'}
				className={`${styles.commonButton} ${this.props.className}`} 
				onClick={() => this.props.onClick ? this.props.onClick(this.props.value) : null}
				onSubmit={() => this.props.onClick ? this.props.onClick(this.props.value) : null}
				value={this.props.children}>
			</input>
		)
	}
}

export default CommonButton;