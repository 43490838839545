import { createStore } from 'redux';

const initialState = {
	hideSidebar: true,
}

export const LayoutReducer = ((layout = initialState, action) => {	
	switch (action.type) {
		case 'LAYOUT__HIDE_SIDEBAR':
			return {
				...layout,
				hideSidebar: true,
			};
		case 'LAYOUT__SHOW_SIDEBAR':
			return {
				...layout,
				hideSidebar: false,
			};
		case 'LAYOUT__TOGGLE_SIDEBAR':
			return {
				...layout,
				hideSidebar: !layout.hideSidebar,
			};
		default:
			return layout;
	}
})

export const LayoutStore = createStore(LayoutReducer);