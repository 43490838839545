import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { connect } from 'react-redux';

import firebase from 'firebase/app';

import { withRouter } from 'react-router-dom';

class SidebarItem extends React.Component {
	render() {
		return (
			<section 
				className={styles.sidebarItem}
				onClick={this.props.onClick}
			>
				{this.props.title}
			</section>
		)
	}
}

class Sidebar extends React.Component {
	render() {
		const signOut = () => {
			firebase.auth().signOut().then(() => {
				console.log('signed out');
				window.location.reload(false);
				}).catch((error) => {
					console.log(error)
				});
		}
		return(
			<section className={classnames(styles.sidebar, {
				[styles.sidebarVisible]: !this.props.state.layout.hideSidebar,
			})}>
				<SidebarItem title="Profile" />
			 	<SidebarItem title="Log Out" onClick={signOut} />
			</section>
		)
	}
}

const mapStateToProps = state => {
  return {
    state
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar));