import { createStore } from 'redux';

const initialRolls = {
	current: null,
	history: [],
	query: '',
	name: '',
	queryMode: null
}

const queryModes = ['CALCULATOR', 'TEXT']

export const DiceRollReducer = ((rolls = initialRolls, action) => {	
	switch (action.type) {
		case 'UPDATE_QUERY':
			return {
				...rolls,
				query: action.query,
				queryMode: (action.queryMode && queryModes.includes(action.queryMode)) ? action.queryMode : rolls.queryMode,
				name: action.name,
			};
		case 'DISPLAY_ROLLS':
			return {
				...rolls,
				current: action.rolls,
				query: '',
				name: ''
			};
		default:
			return rolls;
	}
})

export const DiceRollStore = createStore(DiceRollReducer);