import React from 'react';
import { useHistory } from "react-router-dom";

const CommonInterstitialPage = (props) => {
	const history = useHistory();
	history.push('http://localhost:3000/interstitial');
	return (
		<section>
			<div>
			{props.message}
			</div>
		</section>
	)
}

export const interstitialTransition = (message, forwardURL) => {
	window.setTimeout(() => console.log('page change'), 3000);
	return (
		<CommonInterstitialPage message={message}/>
	)
}

export default CommonInterstitialPage;