import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

import CommonInput from '../../common/CommonInput';
import CommonButton from '../../common/CommonButton';
import CommonCard from '../../common/CommonCard';
import CommonDiceRoll from '../../common/CommonDiceRoll';

import store from '../../reducers';

import Chance from 'chance';

class DiceTextInput extends React.Component {
    /*
        this.props.card - whether the component is wrapped in CommonCard
        this.props.button - whether there's a visible submit button
    */
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.nameRef = React.createRef();
        this.rollDice = this.rollDice.bind(this);
        this.validateDiceText = this.validateDiceText.bind(this);
        this.chance = new Chance();

        this.state = {
            name: '',
        }
    }
    rollDice(e) {
        e.preventDefault();
        if (this.inputRef && this.inputRef.current.value) {
            const roll = new CommonDiceRoll({
                name: this.nameRef.current.value,
                asString: this.inputRef.current.value
            });
            store.dispatch({
                type: 'DISPLAY_ROLLS',
                rolls: [roll]
            });
            store.dispatch({
                type: 'ADD_ROLLS_TO_HISTORY',
                rolls: [roll]
            });
        }
        return false;
    }
    validateDiceText() {

    }
    handleQueryChange(e) {
        store.dispatch({
            type: 'UPDATE_QUERY',
            query: `${e.target.value}`,
            name: this.props.state.rolls.name,
        })
    }
    handleNameChange(e) {
        store.dispatch({
            type: 'UPDATE_QUERY',
            query: this.props.state.rolls.query,
            name: `${e.target.value}`,
        })
    }
    render() {
        const main = (
            <Fragment>
            <form 
                    className={styles.diceTextForm}
                    onSubmit={this.rollDice}
                >
                <CommonInput 
                    type="text"
                    placeholder="e.g. 2d4+2d6+10"
                    ref={this.inputRef}
                    value={this.props.state.rolls.query}
                    onChange={(e) => this.handleQueryChange(e)}
                />
                {this.props.button &&
                <div className={styles.diceTextButton}>
                    <CommonButton
                        type="submit"
                    >
                        Roll
                    </CommonButton>
                </div>
                }
                </form>
                <form 
                    className={styles.diceTextForm}
                    onSubmit={this.rollDice}
                >
                <CommonInput 
                    type="text"
                    placeholder="Give it a name (optional)"
                    ref={this.nameRef}
                    value={this.props.state.rolls.name}
                    onChange={(e) => this.handleNameChange(e)}
                />
                {this.props.button &&
                <div className={styles.diceTextButton}>
                    <CommonButton
                        type="submit"
                    >
                        Roll
                    </CommonButton>
                </div>
                }
                </form>
                </Fragment>
        )
        return (
        <Fragment>
            {this.props.card ?
                <CommonCard>
                    {main}
                </CommonCard>
            : <div>{main}</div>}
        </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

export default connect(mapStateToProps)(DiceTextInput);