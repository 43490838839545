import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';

import DiceResults from '../DiceResults';
import DiceTextInput from '../DiceTextInput';
import DiceRollHistoryTable from '../DiceRollHistoryTable';
import DiceCalculator from '../DiceCalculator';

import CommonCard from '../../common/CommonCard';

class Dashboard extends React.Component {
	render() {
		return (
			<Fragment>
				<CommonCard className={styles.mainDisplay}>
					<DiceResults />
					<DiceTextInput />
				</CommonCard>
				<DiceCalculator />
				<DiceRollHistoryTable />
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
    return {
        state
    }
}

export default connect(mapStateToProps)(Dashboard);