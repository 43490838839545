import React from 'react';

import DiceRollHistoryTable from '../DiceRollHistoryTable';
import ProfileCard from '../ProfileCard';
import { fetchUserDetail } from '../../helpers/api';

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.username = this.props.match.params.username;
	}
	componentWillMount() {
		if (this.username) { fetchUserDetail(this.username) };
	}
	render() {
		return (
		<section>
			<ProfileCard username={this.username}/>
			<DiceRollHistoryTable username={this.username}/>
		</section>
		)
	}
}

export default Profile;