import Chance from 'chance';
import { DateTime } from 'luxon';

class CommonDiceRoll {
	constructor(roll) {
		this.chance = new Chance();
		this.id = roll.id;
		this.createdAt = roll.createdAt ? DateTime.fromISO(roll.createdAt) : DateTime.utc();
		this.name = roll.name || '';
		this.asString = roll.asString;
		this.asTokens = roll.asString.split('+');
		//this.asDice = [];
		this.resultTokens = roll.resultTokens ? roll.resultToken : this.roll();
		this.sum = roll.sum ? roll.sum : this.sumResults();

	}
	roll() {
		const numberRegex = /^\d+$/;
		try {
			return this.asTokens.map((token) => {
				if (numberRegex.test(token)) {
					return parseInt(token);
				}
				return this.chance.rpg(token);
			})
		} catch {
			//TODO handle errors
		}
	}
	sumResults() {
		try {
			return this.resultTokens.flat().reduce((sum, result) => {
	            return sum + result;
	        })
		} catch(e) {
			console.log('error', e);
			return null;
		}
	}
}

export default CommonDiceRoll;