import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

import CommonCard from '../../common/CommonCard';
import CommonButton from '../../common/CommonButton';
import CommonDiceRoll from '../../common/CommonDiceRoll';

import store from '../../reducers';

class DiceCalculator extends React.Component {
	constructor(props) {
		super(props);
		this.handleCalculatorButtonClick = this.handleCalculatorButtonClick.bind(this);
		this.roll = this.roll.bind(this);
		this.back = this.back.bind(this);
		this.rerollLastDice = this.rerollLastDice.bind(this);
	}
	handleCalculatorButtonClick(value) {
		store.dispatch({
			type: 'UPDATE_QUERY',
			query: `${this.props.state.rolls.query}${value}`,
		})
	}
	roll() {
		const diceRoll = new CommonDiceRoll({
			asString: this.props.state.rolls.query,
			name: this.props.state.rolls.name
		});
		store.dispatch({
			type: 'DISPLAY_ROLLS',
			rolls: [diceRoll]
		})
		store.dispatch({
			type: 'ADD_ROLLS_TO_HISTORY',
			rolls: [diceRoll]
		})
	}
	rerollLastDice() {
		if (!this.props.state.user.rolls.history.length) {return null};
		const diceRoll = new CommonDiceRoll({
			asString: this.props.state.user.rolls.history[0].asString
		})
		store.dispatch({
			type: 'DISPLAY_ROLLS',
			rolls: [diceRoll]
		})
		store.dispatch({
			type: 'ADD_ROLLS_TO_HISTORY',
			rolls: [diceRoll]
		})
	}
	clear() {
		store.dispatch({
			type: 'UPDATE_QUERY',
			query: ''
		})
	}
	back() {
		store.dispatch({
			type: 'UPDATE_QUERY',
			query: this.props.state.rolls.query.length ? this.props.state.rolls.query.slice(0, -1) : this.props.state.rolls.query
		})
	}
	render() {
		return (
			<CommonCard
				headerText='Dice Calculator'
			>
			<div className={styles.diceCalculatorWrapper}>
				<div className={styles.diceCalculator}>
				<div className={styles.calculatorNumbers}>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="1">1</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="2">2</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="3">3</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="4">4</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="5">5</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="6">6</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="7">7</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="8">8</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="9">9</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="0">0</CommonButton>
					<CommonButton className={`${styles.calculatorButton} ${styles.calculatorPlusButton}`} onClick={this.handleCalculatorButtonClick} value="+">+</CommonButton>

				</div>

				<div className={styles.calculatorDice}>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="d4">d4</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="d6">d6</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="d8">d8</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="d10">d10</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="d12">d12</CommonButton>
					<CommonButton className={styles.calculatorButton} onClick={this.handleCalculatorButtonClick} value="d20">d20</CommonButton>
				</div>
				</div>
				<div className={styles.actionButtons}>
					<CommonButton className={`${styles.calculatorButton} ${styles.rollButton}`} onClick={this.roll}>Roll</CommonButton>
					<CommonButton className={`${styles.calculatorButton} ${styles.clearButton}`} onClick={this.clear}>Clear</CommonButton>
					<CommonButton className={`${styles.calculatorButton} ${styles.backButton}`} onClick={this.back}>Back</CommonButton>
				</div>
				{this.props.state.user.data &&
				<Fragment>
				<div className={styles.calculatorHorizontalDivider}>
					<div className={styles.calculatorHorizontalDividerLine}>
						<hr />
					</div>
					<div className={styles.calculatorHorizontalDividerText}><span>or</span></div>
				</div>
					<div>
						<CommonButton className={`${styles.calculatorButton} ${styles.rerollButton}`} onClick={this.rerollLastDice}>
							REROLL LAST DICE
						</CommonButton>
					</div>
				</Fragment>
				}
			</div>
			</CommonCard>
		)
	}
}

const mapStateToProps = state => {
    return {
        state
    }
}

export default connect(mapStateToProps)(DiceCalculator);