import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';

import { DateTime } from 'luxon';

import CommonCard from '../../common/CommonCard';
import CommonDiceRoll from '../../common/CommonDiceRoll';
import { getRollHistory } from '../../helpers/api';

class DiceRollHistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rolls : null,
        }
    }
    componentWillMount() {
        const { username } = this.props;
        username && 
        getRollHistory(username).then((snapshot) => {
            const rolls = snapshot.val() 
                ? Object.entries(snapshot.val()).map(([key, roll]) => {
                    return new CommonDiceRoll({
                      ...roll,
                      id: key,
                    })
                  })
                : [];
            this.setState({
                ...this.state,
                rolls
            })
        })
    }
    render() {
        const unsortedHistory = this.state.rolls || this.props.state.user.rolls.history;
        const history = unsortedHistory && unsortedHistory.sort((a, b) => b.createdAt - a.createdAt)
        return (
            <Fragment>
            {history.length ?
            <CommonCard className={styles.historyTable} headerText='History'>
                <header className={styles.tableHeader}>
                    <div className={styles.timeStampColumn}>
                        Time Stamp
                    </div>
                    <div className={styles.nameColumn}>
                        Name
                    </div>
                    <div className={styles.diceColumn}>
                        Dice
                    </div>
                    <div className={styles.resultsColumn}>
                        Results
                    </div>
                </header>
                <ul>
                {history.map((roll) => 
                    <li key={roll.id}>
                        <div className={styles.timeStampColumn}>
                            { roll.createdAt.toLocaleString(DateTime.DATETIME_MED) }
                        </div>
                        <div className={styles.nameColumn}>
                            { roll.name }
                        </div>
                        <div className={styles.diceColumn}>
                            { roll.asString }
                        </div>
                        <div className={styles.resultsColumn}>
                            { roll.sum }
                        </div>
                    </li>
                )}
                </ul>
            </CommonCard>
            : <CommonCard className={styles.historyTable} headerText='History'>
            Create an account to record and share your dice roll history.
            </CommonCard>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

export default connect(mapStateToProps)(DiceRollHistoryTable);