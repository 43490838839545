import React from 'react';
import styles from './styles.module.scss';

import CommonCard from '../../common/CommonCard';

class ProfileCard extends React.Component {
	render() {
		const { username } = this.props;
		return (
		<section>
			<CommonCard headerText={`${username}'s Profile`}>
				<section className={styles.bio}>
					<p>
					This user has not set a bio.
					</p>
				</section>
			</CommonCard>
		</section>
		)
	}
}

export default ProfileCard;