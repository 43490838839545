import { createStore } from 'redux';
import { addRollsToHistory } from '../helpers/api';

const initialState = {
	data: null,
	customData: null,
	rolls: {
		history: [],
	}
}

export const UserReducer = ((user = initialState, action) => {	
	switch (action.type) {
		case 'UPDATE_USER':
			return {
				...user,
				data: action.user,
			};
		case'UPDATE_USER_CUSTOM_DATA':
			return {
				...user,
				customData: action.customData,
			}
		case 'ADD_ROLLS_TO_HISTORY':
			addRollsToHistory(action.rolls);
			return user;
		case 'UPDATE_ROLL_HISTORY':
			return {
				...user,
				rolls: {
					...user.rolls,
					history: action.history
				}
			}
		default:
			return user;
	}
})

export const UserStore = createStore(UserReducer);