import React from 'react';
import styles from './styles.module.scss';

import { Link } from 'react-router-dom';

class SiteHeader extends React.Component {
	render() {
		return (
			<header className={styles.siteHeader}>
				<section className={styles.siteName}>
				<Link to='/'>The Dicey App</Link>
				</section>
			</header>
		)
	}
}

export default SiteHeader;